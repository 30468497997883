import Layout from "../../relaunch/components/layouts/layout/layout"
import Logo from "../../../static/images/ory_logotype.svg"

const seo = {
  title: "Compare Ory to other identity management solutions",
  description:
    "Learn how the Ory Network compares to other identity management solutions on the market.",
}

const ComparisonPage = () => {
  return (
    <Layout>
      <section className="mx-auto flex flex-col py-6 md:py-24">
        <div className="grid grid-cols-6 gap-x-6 gap-y-16 p-2 md:grid-cols-12 lg:gap-x-8 lg:gap-y-24">
          <div className="col-span-full grid grid-cols-6 items-center gap-x-6 gap-y-12 md:grid-cols-12 lg:gap-x-8 lg:gap-y-16">
            <div className="col-span-full grid h-fit gap-y-8 md:col-span-12 md:gap-y-12 lg:col-span-12 xl:col-span-12 xl:gap-y-16">
              <div className="flex flex-col items-center justify-center space-y-8">
                <em className="font-mono text-sm font-bold uppercase not-italic leading-tight text-cyan-500">
                  Ory vs. The competition
                </em>
                <div className="flex flex-col items-center justify-center space-y-4 md:space-y-6">
                  <h1 className="dark:text-cyan-50 text-center text-5xl font-semibold text-indigo-900 md:text-6xl xl:text-7xl">
                    Product teams choose Ory
                  </h1>
                  <p className="dark:text-gray-300 text-center text-lg text-gray-600">
                    Ory has everything companies need to start and grow auth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container mx-auto py-6 md:py-16">
        <div className="my-6 flex flex-col items-center justify-center">
          <h2 className="dark:text-cyan-50 text-center text-2xl font-semibold text-indigo-900 md:text-3xl xl:text-4xl">
            Compare Ory to other identity management solutions
          </h2>
        </div>
        <div className="container mx-auto space-x-0 py-6 md:flex md:flex-nowrap md:space-x-4 md:pt-24">
          <div className="w-full md:flex-1">
            <a
              href="/comparisons/ory-versus-auth0/"
              className="flex w-full items-center justify-center border border-transparent py-2.5 py-24 font-mono text-sm font-bold uppercase leading-tight hover:bg-cyan-200 focus:outline-none"
            >
              <img
                src={Logo}
                alt="ORY Logo"
                style={{ width: "150px", height: "auto" }}
              />
              <div className="pl-2 text-2xl hover:text-cyan-900">Vs. Auth0</div>
            </a>
          </div>
          <div className="w-full md:flex-1">
            <a
              href="/comparisons/ory-vs-keycloak/"
              className="flex w-full items-center justify-center border border-transparent py-2.5 py-24 font-mono text-sm font-bold uppercase leading-tight hover:bg-cyan-200 focus:outline-none"
            >
              <img
                src={Logo}
                alt="ORY Logo"
                style={{ width: "150px", height: "auto" }}
              />
              <div className="pl-2 text-2xl hover:text-cyan-900">
                Vs. Keycloak
              </div>
            </a>
          </div>
        </div>
        <div className="container mx-auto space-x-0 py-6 md:flex md:flex-nowrap md:space-x-4">
          <div className="w-full md:flex-1">
            <a
              href="/comparisons/ory-vs-stytch/"
              className="flex w-full items-center justify-center border border-transparent py-2.5 py-24 font-mono text-sm font-bold uppercase leading-tight hover:bg-cyan-200 focus:outline-none"
            >
              <img
                src={Logo}
                alt="ORY Logo"
                style={{ width: "150px", height: "auto" }}
              />
              <div className="pl-2 text-2xl hover:text-cyan-900">
                Vs. Stytch
              </div>
            </a>
          </div>
          <div className="w-full md:flex-1">
            <a
              href="/comparisons/ory-vs-cognito/"
              className="flex w-full items-center justify-center border border-transparent py-2.5 py-24 font-mono text-sm font-bold uppercase leading-tight hover:bg-cyan-200 focus:outline-none"
            >
              <img
                src={Logo}
                alt="ORY Logo"
                style={{ width: "150px", height: "auto" }}
              />
              <div className="pl-2 text-2xl hover:text-cyan-900">
                Vs. Cognito
              </div>
            </a>
          </div>
        </div>
        <div className="container mx-auto space-x-0 py-6 md:flex md:flex-nowrap md:space-x-4">
          <div className="w-full md:flex-1">
            <a
              href="/comparisons/ory-vs-fusionauth/"
              className="flex w-full items-center justify-center border border-transparent py-2.5 py-24 font-mono text-sm font-bold uppercase leading-tight hover:bg-cyan-200 focus:outline-none"
            >
              <img
                src={Logo}
                alt="ORY Logo"
                style={{ width: "150px", height: "auto" }}
              />
              <div className="pl-2 text-2xl hover:text-cyan-900">
                Vs. FusionAuth
              </div>
            </a>
          </div>
          <div className="w-full md:flex-1">
            <a
              href="/comparisons/ory-vs-firebase/"
              className="flex w-full items-center justify-center border border-transparent py-2.5 py-24 font-mono text-sm font-bold uppercase leading-tight hover:bg-cyan-200 focus:outline-none"
            >
              <img
                src={Logo}
                alt="ORY Logo"
                style={{ width: "150px", height: "auto" }}
              />
              <div className="pl-2 text-2xl hover:text-cyan-900">
                Vs. Firebase
              </div>
            </a>
          </div>
        </div>
      </section>

      <section className="mx-auto flex flex-col space-x-4 px-4 py-6">
        <div className="mx-auto flex flex-wrap items-center justify-center space-x-6 text-sm font-bold leading-tight text-indigo-900 md:flex-nowrap">
          <div className="">Have questions about migrating to Ory?</div>
          <a
            href="/migration/"
            className="dark:text-gray-300 dark:hover:text-cyan-900 dark:hover:bg-cyan-50 dark:text-gray-300 ring-solid dark:ring-gray-50 mt-2 flex h-auto w-fit w-full items-center justify-center whitespace-nowrap border border-transparent px-5 py-2.5 text-sm font-medium text-gray-600 ring-1 ring-gray-900 hover:bg-gray-900 hover:text-cyan-50 focus:outline-none focus:ring focus:ring-cyan-300 focus:ring-cyan-500 md:mt-0 md:w-min xl:py-3 xl:text-base"
            target="_blank"
          >
            Learn more about making the switch
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 256 256"
              className="ml-2"
            >
              <rect width="256" height="256" fill="none"></rect>
              <line
                x1="40"
                y1="128"
                x2="216"
                y2="128"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="24"
              ></line>
              <polyline
                points="144 56 216 128 144 200"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="24"
              ></polyline>
            </svg>
          </a>
        </div>
        <div>
          <p className="mt-6 text-center text-xs">
            Can't find the answer your looking for? Our product support
            specialists at{" "}
            <a href="mailto:support@ory.sh" alt="mail link">
              support@ory.sh
            </a>{" "}
            are here to help.
          </p>
        </div>
      </section>
    </Layout>
  )
}

export const Head = () => (
  <>
    <title>{seo.title}</title>
    <meta name="description" content={seo.description} />
    <meta property="og:title" content={seo.title} />
    <meta property="og:description" content={seo.description} />
    <meta property="og:type" content="website" />
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:author" content="ORY" />
    <meta property="twitter:title" content={seo.title} />
    <meta property="twitter:description" content={seo.description} />
  </>
)

export default ComparisonPage
