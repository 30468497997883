import cn from "classnames"
import { ReactNode } from "react"
import { Minibanner } from "../../freestanding/minibanner/minibanner"
import Footer from "../footer/footer"
import Navigation from "../navigation/navigation"
import "../../../styles/global.css"
import * as styles from "./layout.module.css"

interface PropTypes {
  children: ReactNode
  mockNavigation?: boolean
  minimalNavigation?: boolean | null
  bgNext?: boolean
  stickyNavigation?: boolean
}

const Layout = ({
  children,
  mockNavigation = false,
  minimalNavigation,
  stickyNavigation,
  bgNext,
}: PropTypes) => {
  // custome dark mode
  // if (typeof window !== "undefined") {
  //   if (
  //     localStorage.getItem("color-theme") === "dark" ||
  //     (!("color-theme" in localStorage) &&
  //       window.matchMedia("(prefers-color-scheme:dark)").matches)
  //   ) {
  //     document.documentElement.classList.add("dark")
  //   } else {
  //     document.documentElement.classList.remove("dark")
  //   }
  // }

  return (
    <div
      className={cn(
        styles.globals,
        "dark:bg-gray-800 flex h-full flex-col marker:bg-gray-50",
        bgNext ? "bg-white" : "bg-gray-50",
      )}
    >
      <Navigation
        mockNavigation={mockNavigation}
        minimalNavigation={minimalNavigation}
        stickyNavigation={stickyNavigation}
      />
      <main>{children}</main>
      <Footer minimalNavigation={minimalNavigation} bgNext={bgNext} />
    </div>
  )
}

export default Layout
